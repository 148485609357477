<template>
  <div class="loading-page">
    <van-loading type="spinner" />
  </div>
</template>

<script>
import { Loading } from "vant";
export default {
  name: "CashierHandlePage",
  components: {
    [Loading.name]: Loading,
  },
  created() {
    let mall_url = this.$route.query.mall_url;
    const code = this.$route.query.code;
    mall_url = `${mall_url}&code=${code}`;
    window.location.replace(mall_url);
  },
};
</script>

<style lang="less" scoped>
.loading-page {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>